import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

const HomePage = (props) => {
    const { t } = useTranslation();
    let isArabic = localStorage.getItem('i18nextLng');
    const accessToken = localStorage.getItem("ecomaccesstoken");
    const dataFetched = useRef();
    const dataFetched3 = useRef();

    return (
        <>
            {/* <div className={openmodal.showLoginModel || openmodal.showRegisterModel || openmodal.showemailvarifymodal || openmodal.forgetpassword || openmodal.resetpassword || openmodal.newpassword? 'ecommerce-main-layout-bodyContent overflow-hidden vh-100' :  isFilterOpen ? 'mainbody-overflowSet ecommerce-main-layout-bodyContent' : 'ecommerce-main-layout-bodyContent'}> */}
            <div className={'ecommerce-main-layout-bodyContent'}>
                {/* product filter */}
                <div className='main-WebHeader-filter-section'></div>
            </div >
        </>
    );
}
export default HomePage;