import React, { useEffect, useState } from 'react'
import PlusIcon from '../../assets/icons/PlusIcon';
import { _Api, _Apiauth } from "../../../common/api"
import { Link, useLocation } from 'react-router-dom';
import Appointmentmodal from './Appointmentmodal';
import { useTranslation } from 'react-i18next';
import Spinner from "../../../common/Spinner";
import RightArrow from '../../../../../admin/common/icons/right-arrow';
import { options } from '@fullcalendar/core/preact.js';
import Select from "react-select";
import DatePicker from 'react-datepicker'
import appointmentMap from '../../assets/images/appointment_map.png' 
import Calendar from '../../../../../admin/common/icons/sidebar/Calendar';
import RightArrowIcon from '../../assets/icons/RightArrowIcon';
const Appointment = () => {
    const { state } = useLocation()
    const [loadeing, setloading] = useState(true)
    const { t } = useTranslation();
    const [appointmentdtaa, setAppointmentdtaa] = useState([])
    const [locationdata, setLocationdata] = useState([])
    const [allfromdata, setAllfromdata] = useState()
    const [isOpenOrder, setIsOpenOrder] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [formData, setFormData] = useState({store: '', date: '', time: '', name: '', email: '', phone: '', policy: '', message: ''});
    const [selectdata, setSelectdata] = useState();

    const getappointmentdata = async () => {
        setloading(true)
        try {
            let cartids = [];
            if (state?.checkoutItems?.length) { state?.checkoutItems?.map((result) => cartids.push(result?.id)) }
            let postdata = {
                cart_ids: cartids
            }
            let res = await _Api(postdata, `order/getCartDetails`)
            if (res?.statusCode === 200) {
                setloading(false)
                let array = [...res?.data];
                array?.forEach((result) => {
                    result.qty = parseInt(state?.checkoutItems.filter(e => e?.id === result?.id)[0]['qty']);
                })
                setAppointmentdtaa(array)
                if(res?.data?.length){
                    console.log("we have length")
                }else{
                    console.log("we don't have anything")
                }
                setAllfromdata((privcestate) => ({
                    ...privcestate,
                    product: state?.checkoutItems
                }))
                if (res?.deleted_ids?.length) {
                    state?.checkoutItems.forEach((result) => {
                        if (!res?.deleted_ids.includes(result?.id)) {
                            let data = []
                            data.push(result)
                            localStorage.setItem('CartItems', JSON.stringify(data));
                        }
                    })
                }
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const getlocationdata = async () => {
        try {
            let postdata = {}
            let res = await _Api(postdata, `redis/shipingMathod`)
            if (res?.statusCode === 200) {
                if(res?.StorePickup?.length){
                    locationdata.push()
                }
                setLocationdata(res?.StorePickup)
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const toggleDiv = () => {
        setIsOpenOrder(!isOpenOrder);
    };
    const getcountry = async () => {
        try {
            let postdata = {}
            let res = await _Apiauth(postdata, `redis/countryList`) 
            if (res?.statusCode === 200) {
                let phonecode = []
                res?.data.forEach((result) => {
                    phonecode.push({
                        label: result?.phoneCode,
                        value: result?.phoneCode
                    })
                })
                setSelectdata(phonecode)
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const timeArray =[
        {key: "00:00", value: "00:00"}, {key: "01:00", value: "01:00"}, {key :"02:00", value: "02:00"}
    ]
    const setalldata = (keyname, value) => {
        console.log(keyname, value, "check")
        setAllfromdata((state) => ({
            ...state,
            [keyname]: value
        }))
    }
    useEffect(() => {
        getappointmentdata()
        getlocationdata()
        getcountry()
        // eslint-disable-next-line
    }, [])


    const interestArray = [
        "Earrings", "Necklaces & Pendants", "Rings", "Anklets", "Other Inquiries"
    ]

    const location = [
        "centeral lefparao", "Central world"
    ]

    const handleChange = (e, type) => {
        console.log(e, type, "on change")
    }

    const handleAppointmentConfirm = () =>{
        console.log(formData, allfromdata, "check form data")
    }

    return (
        <div className='appointmentPage ecommerce-main-layout-bodyContent'>
            <div className='appointmentPage-header'><span className='com-path-show-currectPath'>{t("Categories")}</span> <span className='SlashPath'><RightArrow/></span> <span className='com-path-show-startPath'>{t("Appointment")}</span></div>
          
                <div className='appointmentPage-container'>
                    <div className='appointmentPage-container-headerBox'>
                        <div className={`appointmentPage-container-headerBox-headerName`}>{t("Book an Appointment")}</div>
                        <div className={`appointmentPage-container-headerBox-subhead`}>{t("We look forward to welcoming you!")}</div>
                    </div>
                    {loadeing ?
                        (<div className=" w-100 d-flex justify-content-center align-items-center  ">
                            <Spinner />
                        </div>) :
                        // appointmentdtaa?.length ?
                        //     <div className='appointmentPage-container-contentPage' >
                        //         <div className={`tabHideOrder-box ${isOpenOrder ? 'open' : 'bg-white'}`} onClick={toggleDiv}>
                        //             <p className='tabHideOrder-box-contentText'>{t("Hide order Appointment")}</p>
                        //             <span className='arrow'></span>
                        //         </div>
                        //         <div className={isOpenOrder ? 'active wishlistMain-container-bodyProCont col-8 h-100  overflow-auto appointmentPage-container-contentPage-leftSideBox' : ' wishlistMain-container-bodyProCont col-8 h-100  overflow-auto appointmentPage-container-contentPage-leftSideBox'} id='appointmentOrder'
                        //             style={{ scrollbarColor: "transparent transparent" }}>
                        //             {appointmentdtaa?.length ? appointmentdtaa.map((result, key) => {
                        //                 return (
                        //                     <div className='wishlistMain-container-bodyProCont-singlePro  ps-0 appointmentPage-container-contentPage-leftSideBox-singleProductdetail' key={key}>
                        //                         <div className='wishlistMain-container-bodyProCont-singlePro-productType'>
                        //                             <div className='wishlistMain-container-bodyProCont-singlePro-productType-imageBox'>
                        //                                 <span className='product-img-span'><img src={result?.main_image} alt='' /></span>
                        //                             </div>
                        //                             <div className='wishlistMain-container-bodyProCont-singlePro-productType-details'>
                        //                                 <div className='wishlistMain-container-bodyProCont-singlePro-productType-details-firstBox'>
                        //                                     <p className='wishlistMain-container-bodyProCont-singlePro-productType-details-firstBox-productName'>{result?.name}</p>
                        //                                     <div className='wishlistMain-container-bodyProCont-singlePro-productType-details-firstBox-content'>
                        //                                         <p className='contentPara'>{result?.SKU}</p>
                        //                                         <p className='contentPara'>{result?.Metal}</p>
                        //                                         <p className='contentPara'><span>{t("Size")}:</span> {result?.Size} </p>
                        //                                     </div>
                        //                                 </div>
                        //                             </div>
                        //                         </div>
                        //                     </div>
                        //                 )
                        //             }) : ""}
                        //         </div>

                        //         <div className='col h-100 overflow-auto d-flex flex-column gap-4  appointmentPage-container-contentPage-rightSideBox'>
                        //             <div className='appointmentTabMain_header'>{t("Appointment")}</div>
                        //             {locationdata?.length ? locationdata.map((result, key) => {
                        //                 return (
                        //                     <div className="com-ShippAddressBox w-100" key={key} >
                        //                         <div className="com-ShippAddressBox-contentBox" key={key}>
                        //                             <div className="userDetailShipp">
                        //                                 <h3 className="com-ShippAddressBox-contentBox-boldText">{result?.name}</h3>
                        //                                 <p className="com-ShippAddressBox-contentBox-paraText">{result?.store_Address}</p>
                        //                             </div>
                        //                             <div className="d-flex gap-1">
                        //                                 <h3 className="com-ShippAddressBox-contentBox-boldText">{t("Phone Number")}:</h3>
                        //                                 <p className="com-ShippAddressBox-contentBox-lightText">{result?.phone}</p>
                        //                             </div>
                        //                             <div className="d-flex gap-1" >
                        //                                 <h3 className="com-ShippAddressBox-contentBox-boldText">{t("Store Hours")}</h3>
                        //                                 <p className="com-ShippAddressBox-contentBox-lightText"></p>
                        //                             </div>
                        //                             <div className='shipping-timeSetBox' >
                        //                                 {Object.keys(result?.store_timeSchedule || {}).map((key) => (
                        //                                     <div className='shipping-timeSetBox-singleDay' key={key}>
                        //                                         <div className='shipping-timeSetBox-singleDay-day'>
                        //                                             <p className='com-ShippAddressBox-contentBox-lightText' >{key}</p>
                        //                                         </div>
                        //                                         <div className='shipping-timeSetBox-singleDay-time'>
                        //                                             <p className='com-ShippAddressBox-contentBox-lightText'>{result?.store_timeSchedule[key]}</p>
                        //                                         </div>
                        //                                     </div>
                        //                                 ))}
                        //                             </div>
                        //                             <button className="com-ecomSendBtn mt-2 w-100" data-bs-toggle="modal" data-bs-target="#Appointmentmodal"
                        //                                 onClick={() => setAllfromdata((start) => ({
                        //                                     ...start,
                        //                                     location: result,
                        //                                     "name": "",
                        //                                     "mobile": "",
                        //                                     "email": "",
                        //                                     "message": "",
                        //                                     "appointment_date": "",
                        //                                     "phonecode": ""
                        //                                 }))}
                        //                             >
                        //                                 {t("Appointment")}
                        //                             </button>
                        //                         </div>
                        //                     </div>

                        //                 )
                        //             }) : ""}
                        //         </div>
                        //     </div>
                        //     :
                        //     <div className='wishlistMain-container-bodyBox'>
                        //         <div className='wishlistMain-container-bodyBox-innerContent'>
                        //             <p className='wishlistMain-container-bodyBox-innerContent-para'>{t("This list is empty")}.</p>
                        //             <Link className='text-decoration-none' to="/">
                        //                 <div className='wishlistMain-container-bodyBox-innerContent-addProductBtnBox'>
                        //                     <PlusIcon width={16} height={16} color={"#FFFFFF"} />
                        //                     <span>{t("Add Product")}</span>
                        //                 </div>
                        //             </Link>

                        //         </div>
                        //     </div>
                        <div className='appointmentPage-container-content'>
                            <div className='appointmentPage-container-content-wrapper'>
                                <div className='appointmentPage-container-content-wrapper-left'>
                                    <div className='appointmentPage-container-content-wrapper-left-top'>
                                        <div className='appointmentPage-container-content-wrapper-left-top-details'>
                                            <div className='appointmentPage-container-content-wrapper-left-top-details-title'>{t("Find Your Store")}</div>
                                            <div className="appointmentPage-container-content-wrapper-left-top-details-singledetail">
                                                <div className="appointmentPage-container-content-wrapper-left-top-details-singledetail-name">
                                                    <span className='headings-name'>{t("Store")}</span>
                                                </div>
                                                <div className="appointmentPage-container-content-wrapper-left-top-details-singledetail-option">
                                                    <Select
                                                        options={options}
                                                        className=" select-set select p-0 main-content-wrapper-body-top-status-bar-select-num"
                                                        height='46px'
                                                        width='100%'
                                                        value={formData?.store || null}
                                                        onChange={handleChange}
                                                        isSearchable={false}
                                                        />
                                                </div>
                                            </div>
                                            <div className='appointmentPage-container-content-wrapper-left-top-details-title'>{t("Your Appointment")}</div>
                                            <div className='appointmentPage-container-content-wrapper-left-top-details-holder'>
                                            <div className="appointmentPage-container-content-wrapper-left-top-details-singledetail">
                                                <div className="appointmentPage-container-content-wrapper-left-top-details-singledetail-name">
                                                    <span>{t("Date")}</span>
                                                </div>
                                                <div className="appointmentPage-container-content-wrapper-left-top-details-singledetail-option">
                                                <DatePicker
                                                    selected={allfromdata?.appointment_date}
                                                    onChange={(date) => setalldata("appointment_date", date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    className=''
                                                    minDate={new Date()}
                                                    autoComplete='none'
                                                    showIcon={true} 
                                                    icon={<Calendar color="#6A6A6A" width="18px" height="18px"/>}
                                                />
                                                </div>
                                                <div className='appointmentPage-container-content-wrapper-left-top-details-singledetail-message'>
                                                    {t("All dates and times are in GMT+07:00")}
                                                </div>
                                            </div>
                                            <div className="appointmentPage-container-content-wrapper-left-top-details-singledetail">
                                                <div className="appointmentPage-container-content-wrapper-left-top-details-singledetail-name">
                                                    <span className='headings-name'>{t("Time")}</span>
                                                </div>
                                                <div className="appointmentPage-container-content-wrapper-left-top-details-singledetail-option">
                                                    <Select
                                                        options={timeArray}
                                                        className=" select-set select p-0 main-content-wrapper-body-top-status-bar-select-num"
                                                        height='46px'
                                                        width='100%'
                                                        value={allfromdata?.time}
                                                        onChange={(date) => setalldata("time", date?.value)}
                                                        isSearchable={false}
                                                        />
                                                </div>
                                                <div className='appointmentPage-container-content-wrapper-left-top-details-singledetail-error'>
                                                    {t("All dates and times are in GMT+07:00")}
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div className='appointmentPage-container-content-wrapper-left-top-map'>
                                            <img src={appointmentMap} alt='map'/>
                                        </div>
                                    </div>
                                    { appointmentdtaa?.length ? 
                                   
                                    <div className='appointmentPage-container-content-wrapper-left-areaofinterest'>
                                        <div className='appointmentPage-container-content-wrapper-left-areaofinterest-title'><div>{("Area of Interest")} <span className='sub'>{appointmentdtaa?.length ? `${appointmentdtaa?.length} Items` : ""}</span></div><div className='continue'>{("Continue Shopping")} <span><RightArrow color="#A3A3A3"/></span></div></div>
                                        <div>
                                        <div className="ecommerce-main-layout-products-container-show-all_products">
                                        {appointmentdtaa?.map((item, key) => {
                                        return (

                                        <Link to={`/product/${item?.motherProduct?.Slug}`} state={{ id: item?._id }} className='ecommerce-recent_view-container-inner-product' key={key}>
                                        <div className='ecommerce-recent_view-container-inner-product-image'>
                                            <div className="ecommerce-recent_view-container-inner-product-image-imgBoxShow">
                                            <img src={item?.main_image ? item?.main_image : ""} alt='product' /></div>
                                        </div>
                                        <div className="ecommerce-recent_view-container-inner-product-details text-decoration-none ">
                                            <div className='ecommerce-recent_view-container-inner-product-details-wrapper'>
                                            <div className="ecommerce-recent_view-container-inner-product-details-wrapper-productName">
                                                {item?.name}
                                            </div>
                                            <span>{item?.SKU} </span>
                                            </div>
                                        </div>
                                        </Link>

                                    )
                                    })}
                                    </div>
                                    </div>
                                    </div>
                                    :
                                    <div className='appointmentPage-container-content-wrapper-left-interest'>
                                    <div className='appointmentPage-container-content-wrapper-left-interest-title'>{("Please select one or more interests")} <span className='important'>*</span></div>
                                    <div className='appointmentPage-container-content-wrapper-left-interest-interestlist'>
                                        {interestArray.map((item,i)=>{
                                            return(
                                            <div className='appointmentPage-container-content-wrapper-left-interest-interestlist-option' key={i}>
                                                <div className="com-ecomCheckBox">
                                                    <input type="checkbox" className="itemscheckbox com-ecomCheckBox-inputcheck me-2 cursor-pointer"
                                                        id={item} />
                                                    <label className="com-ecomCheckBox-labelBox cursor-pointer fw-semibold appointmentPage-container-content-wrapper-left-interest-interestlist-option-tag" htmlFor={item}> {t(`${item}`)}</label>
                                                </div>
                                            </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                }
                                </div>
                                <div className='appointmentPage-container-content-wrapper-right'>
                                    <div className='appointmentPage-container-content-wrapper-right-title'>{("Your Details")}</div>
                                    <div className="appointmentPage-container-content-wrapper-left-top-details-singledetail">
                                                <div className="appointmentPage-container-content-wrapper-left-top-details-singledetail-name">
                                                    <span className='headings-name'>{t("Full Name")}<span className='important'>*</span></span>
                                                </div>
                                                <div className="appointmentPage-container-content-wrapper-left-top-details-singledetail-option">
                                                   <input className='appointmentPage-container-content-wrapper-left-top-details-singledetail-option-input' type='text' value={allfromdata?.name}
                                    onChange={(e) => setalldata("name", e.target.value)}/>
                                                </div>
                                                <div className='appointmentPage-container-content-wrapper-left-top-details-singledetail-error'>
                                                    {t("Please enter your full name.")}
                                                </div>
                                            </div>
                                            <div className="appointmentPage-container-content-wrapper-left-top-details-singledetail">
                                                <div className="appointmentPage-container-content-wrapper-left-top-details-singledetail-name">
                                                    <span className='headings-name'>{t("Email")}<span className='important'>*</span></span>
                                                </div>
                                                <div className="appointmentPage-container-content-wrapper-left-top-details-singledetail-option">
                                                   <input className='appointmentPage-container-content-wrapper-left-top-details-singledetail-option-input' type='text'  value={allfromdata?.email}
                                    onChange={(e) => setalldata("email", e.target.value)}/>
                                                </div>
                                                <div className='appointmentPage-container-content-wrapper-left-top-details-singledetail-error'>
                                                    {t("Please enter your Email.")}
                                                </div>
                                            </div>
                                            <div className="appointmentPage-container-content-wrapper-left-top-details-singledetail ">
                                                <div className="appointmentPage-container-content-wrapper-left-top-details-singledetail-name">
                                                    <span className='headings-name'>{t("Phone Number")}<span className='important'>*</span></span>
                                                </div>
                                                <div className="appointmentPage-container-content-wrapper-left-top-details-singledetail-option phonefield">
                                                    <Select
                                                        options={selectdata}
                                                        className="ecommerce-dropdown noborder border-0"
                                                        classNamePrefix="common-select"
                                                        value={{ label: allfromdata?.phonecode, value: allfromdata?.phonecode }}
                                                        onChange={(e) => setalldata("phonecode", e.value)}
                                                    />
                                                    <input className='appointmentPage-container-content-wrapper-left-top-details-singledetail-option-input' type='number' value={allfromdata?.mobile}
                                                    onChange={(e) => setalldata("mobile", e.target.value)}/>
                                               
                                                </div>
                                                <div className='appointmentPage-container-content-wrapper-left-top-details-singledetail-error'>
                                                    {t("Please enter your Phone No.")}
                                                </div>
                                            </div>
                                            <div className='appointmentPage-container-content-wrapper-right-inputtype'>
                                            <div className="appointmentPage-container-content-wrapper-left-top-details-singledetail">
                                                <div className="appointmentPage-container-content-wrapper-left-top-details-singledetail-name">
                                                    <span className='headings-name'>{t("Message")}</span>
                                                </div>
                                                <div className="appointmentPage-container-content-wrapper-left-top-details-singledetail-option">
                                                   <textarea className='appointmentPage-container-content-wrapper-left-top-details-singledetail-option-textarea' placeholder='Message goes here'  value={allfromdata?.message}
                                                    onChange={(e) => setalldata("message", e.target.value)}></textarea>
                                                </div>
                                                <div className='appointmentPage-container-content-wrapper-left-top-details-singledetail-error'>
                                                    {t("All dates and times are in GMT+07:00")}
                                                </div>
                                            </div>
                                            </div>
                                            <div className='appointmentPage-container-content-wrapper-right-tnc'>
                                                <div className='appointmentPage-container-content-wrapper-right-tnc-one'>
                                                <div className="com-ecomCheckBox">
                                                        <input type="checkbox" className="itemscheckbox com-ecomCheckBox-inputcheck me-2 cursor-pointer"
                                                            id="one" />
                                                        <label className="com-ecomCheckBox-labelBox cursor-pointer fw-semibold appointmentPage-container-content-wrapper-left-interest-interestlist-option-tag" htmlFor="one"> {t("I would like to receive updates from JEWELRY using any of the method(s) that I have provided above. You can unsubscribe at any time.")}</label>
                                                </div>
                                                </div>
                                                <div className='appointmentPage-container-content-wrapper-right-tnc-one'>
                                                <div className="com-ecomCheckBox">
                                                        <input type="checkbox" className="itemscheckbox com-ecomCheckBox-inputcheck me-2 cursor-pointer"
                                                            id="two" />
                                                        <label className="com-ecomCheckBox-labelBox cursor-pointer fw-semibold appointmentPage-container-content-wrapper-left-interest-interestlist-option-tag" htmlFor="two"><span className='important'>*</span> By submitting this form, I certify that I have read and accepted the <a className='pp'> Privacy Policy</a> of JEWELRY.</label>
                                                </div>
                                                <div className='appointmentPage-container-content-wrapper-left-top-details-singledetail-error'>
                                                    {t("Please confirm that you agree to the Global Privacy Policy by checking the checkbox above.")}
                                                </div>
                                                </div>
                                                <div></div>
                                            </div>
                                </div>
                            </div>
                            <div className='appointmentPage-container-content-bottom disable'>
                            <button className=""
                                                        onClick={() => handleAppointmentConfirm()}
                                                    >
                                                        {t("Book My Appointment")}
                                                    </button>
                            </div>
                        </div>
                             }
                      
                </div>
           
        </div >
    )
}

export default Appointment