import React from 'react'

export default function UserIcon() {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 14.75C13 12.6792 10.3135 11 7 11C3.6865 11 1 12.6792 1 14.75M7 8.75C6.00544 8.75 5.05161 8.35491 4.34835 7.65165C3.64509 6.94839 3.25 5.99456 3.25 5C3.25 4.00544 3.64509 3.05161 4.34835 2.34835C5.05161 1.64509 6.00544 1.25 7 1.25C7.99456 1.25 8.94839 1.64509 9.65165 2.34835C10.3549 3.05161 10.75 4.00544 10.75 5C10.75 5.99456 10.3549 6.94839 9.65165 7.65165C8.94839 8.35491 7.99456 8.75 7 8.75Z" fill="#A4A4A4" />
      <path d="M13 14.75C13 12.6793 10.3135 11 7 11C3.6865 11 1 12.6793 1 14.75H13ZM7 8.75C6.00544 8.75 5.05161 8.35491 4.34835 7.65165C3.64509 6.94839 3.25 5.99456 3.25 5C3.25 4.00544 3.64509 3.05161 4.34835 2.34835C5.05161 1.64509 6.00544 1.25 7 1.25C7.99456 1.25 8.94839 1.64509 9.65165 2.34835C10.3549 3.05161 10.75 4.00544 10.75 5C10.75 5.99456 10.3549 6.94839 9.65165 7.65165C8.94839 8.35491 7.99456 8.75 7 8.75Z" stroke="#A4A4A4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  )
}
