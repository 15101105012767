import React, { useEffect, useState } from 'react';
import MinusIcon from "../../assets/icons/MinusIcon";
import PlusIcon from "../../assets/icons/PlusIcon";
import { Link, useNavigate } from 'react-router-dom';
import { _Api } from '../../../common/api';
import Spinner from '../../../common/Spinner';
import { useTranslation } from 'react-i18next';
import { toFormatPrice } from '../../../common/Formateprice';
import RightArrow from '../../../../../admin/common/icons/right-arrow';
import EditIcon from '../../../../template_one/default/assets/icons/EditIcon';
import DeleteIcon from '../../assets/images/deleteicon-grey.png';
import DeleteIconRed from "../../assets/images/deleteIcon.svg";
import DeleteConfirmation from '../../components/DeleteConfirmation';
import PrintIcon from '../../assets/icons/PrintIcon';
import DownloadIcon from '../../assets/icons/DownloadIcon';

const ShoppingCart = () => {

    let shipdata = JSON.parse(localStorage.getItem('CartItems'))
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [cartdata, setCartdata] = useState([])
    const [loading, setLoading] = useState(false)
    const [checkid, setCheckid] = useState([])
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const getQuantity = (index, lable, qty) => {
        let array = [...cartdata]
        if (lable === "plus") {
            array[index].qty = qty + 1
        } else if (lable === "minus") {
            array[index].qty = qty - 1
        }
        setCartdata(array)
        let setupdatearray = []
        array.forEach((result) => {
            setupdatearray.push({
                id: result?.id,
                qty: result?.qty
            })
        })
        localStorage.setItem('CartItems', JSON.stringify(setupdatearray));
    }

    const deleteItems = (removeid) => {
        let array = [...cartdata]
        let update = array.filter(e => e?.id !== removeid);
        setCartdata(update);
        let setupdatearray = []
        update.forEach((result) => {
            setupdatearray.push({
                id: result?.id,
                qty: result?.qty
            })
        })
        localStorage.setItem('CartItems', JSON.stringify(setupdatearray));

    }
    const getcarts = async (shipdata) => {
        setLoading(true)
        // if (shipdata?.length) { await shipdata?.map((result) => cartids.push(result?.id)) }        
        let postdata = {}
        const result = shipdata?.map(item => ({
            diamond_id: item?.diamonddetails?.diamonddata?.id,
            id: item?.id,
            qty: item?.qty,
            temp_cart_id: item?.temp_cart_id
        }));

        // shipdata?.map((result) => {
        //     if (result?.diamonddetails) {
        //         details.push(
        //             {
        //                 "id": result?.id,
        //                 "qty": result?.qty,
        //                 "diamond_id": result?.diamonddetails?.diamonddata?.id,
        //                 "diamond_note": result?.diamonddetails?.diamonddata?.note,
        //                 "metal": result?.diamonddetails?.productdiamond?.metal?.value,
        //                 "shape": result?.diamonddetails?.productdiamond?.shape?.shapeId,
        //                 "size": result?.diamonddetails?.productdiamond?.size?._id,
        //                 "stone": result?.diamonddetails?.productdiamond?.stone?.value,
        //                 "pointer": result?.diamonddetails?.productdiamond?.pointer?.label,
        //                 "temp_cart_id": result?.temp_cart_id,
        //                 "custom_design": {
        //                     "engraving_text": result?.custom_design?.customizeText,
        //                     "side": result?.custom_design?.inorout,
        //                     "engraving_position": result?.custom_design?.textposition?.label,
        //                     "engraving_logo": result?.custom_design?.imageFile,
        //                     "font": result?.custom_design?.fonts?.label,
        //                     "logo_position": result?.custom_design?.imgPosition?.label
        //                 },
        //             }
        //         )
        //     } else {
        //         details.push(result)
        //     }
        // })
        try {
            postdata = { "diamond_ids": result }

            let res = await _Api(postdata, `order/getCartDetails`)
            if (res?.statusCode === 200) {
                let array = [...res?.data];
                array?.map((result) => {
                    return result.qty = parseInt(shipdata.filter(e => e?.id === result?.id)[0]['qty']);
                })
                setCartdata(array)
                setLoading(false)
                if (res?.deleted_ids?.length) {
                    shipdata.forEach((result) => {
                        if (!res?.deleted_ids.includes(result?.id)) {
                            let data = []
                            data.push(result)
                            localStorage.setItem('CartItems', JSON.stringify(data));
                        }
                    })
                }
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }


    const checkcart = (event, data) => {
        if (data?.id) {
            setCheckid((prevChecked) =>
                event.target.checked
                    ? data?.diamond ? [...prevChecked, { id: data?.id, qty: data?.qty, temp_cart_id: data?.temp_cart_id, diamond_id: data?.diamond?.id, price: (parseFloat(data?.UnFormatPrice) + parseFloat(data?.diamond?.UnFormatPrice)) }] : [...prevChecked, { id: data?.id, qty: data?.qty, price: data?.UnFormatPrice }]
                    : prevChecked.filter((item) => item?.id !== data?.id)
            )
        } else {
            let fulldata = []
            if (event.target.checked) {
                cartdata?.forEach((result) => {
                    if (result?.diamond) {
                        fulldata.push({ id: result?.id, qty: result?.qty, temp_cart_id: result?.temp_cart_id, diamond_id: result?.diamond?.id, price: (parseFloat(result?.UnFormatPrice) + parseFloat(result?.diamond?.UnFormatPrice)) })
                    } else {
                        fulldata.push({ id: result?.id, qty: result?.qty, price: result?.UnFormatPrice })
                    }
                });

            }
            setCheckid(fulldata)
        }
    }

    const handleClearAll = () => {
        if (checkid.length === cartdata.length) {
            let setupdatearray = [];
            setCartdata((prev) => []);
            setCheckid((prev) => []);
            localStorage.setItem('CartItems', JSON.stringify(setupdatearray));
        }
    }
    const negvigatecheckout = (lable, data) => {
        if (lable === "checkout") {
            navigate("/shipping", { state: { checkoutItems: data } })
        }
        if (lable === "appoinment") {
            navigate("/appointment", { state: { checkoutItems: data } })
        }
    }
    useEffect(() => {
        getcarts(shipdata)
        // eslint-disable-next-line
    }, [])

    const showDeleteConfirmation = () =>{
        setIsPopupVisible(true);
      }

      const handleConfirm = (id) => {
        if(checkid?.length === cartdata?.length){
            handleClearAll();
            console.log("all")
        }else{
            deleteItems(id);
            console.log("one by one")
        }
        setIsPopupVisible(false);
      };
      
      const handleCancel = () => {
        console.log("delete it is")
        setIsPopupVisible(false);
      };

    return (
        <div className=' ecommerce-main-layout-bodyContent'>
            <div className='shoppingCartPage'>
                <div className='shoppingCartPage-header'>
                    <span>{t("Categories")}</span> <span><RightArrow /></span> <span>{t("Shopping Cart")}</span>
                </div>
                <div className='shoppingCartPage-headBox'>
                    <span className='shoppingCartPage-headBox-heading'>{t("My Shopping Cart")}</span>
                    <div className='shoppingCartPage-headBox-icons'>
                        <span><EditIcon color={"#767676"}/></span>
                        <span><DownloadIcon color={"#767676"}/></span>
                        <span><PrintIcon color={"#767676"}/></span>
                    </div>
                </div>
                {loading ? (<div className='h-100 d-flex justify-content-center align-items-center'>

                    <Spinner />

                </div>) : (
                    <>
                        {cartdata?.length ? (
                            <>
                                <div className='shoppingCartPage-cartTableBox'>
                                    <div className='w-100'>
                                        <div className='shoppingCartPage-cartTableBox-header'>
                                            <div className='shoppingCartPage-cartTableBox-header-rightcontent'>
                                                <div className='shoppingCartPage-cartTableBox-header-rightcontent-item'>( {checkid?.length} / {cartdata?.length} {t("Item")} )</div>
                                                <div className='shoppingCartPage-cartTableBox-header-rightcontent-clear' onClick={() => showDeleteConfirmation()}>
                                                    <img alt='delete' src={checkid.length === cartdata?.length ? DeleteIconRed : DeleteIcon} /> {t("Clear All")}</div>
                                                <div className='shoppingCartPage-cartTableBox-header-rightcontent-appointment'>{t("Book an Appointment")}</div>
                                            </div>
                                            <div className='shoppingCartPage-cartTableBox-header-wrapper'>
                                                <div className='shoppingCartPage-cartTableBox-header-wrapper-left'>
                                                    <div className="com-ecomCheckBox">
                                                        <input type="checkbox" className="itemscheckbox com-ecomCheckBox-inputcheck me-2 cursor-pointer"
                                                            onChange={(e) => checkcart(e, "")}
                                                            checked={checkid?.length === cartdata?.length}
                                                            id='allcartcheck' />
                                                        <label className="com-ecomCheckBox-labelBox cursor-pointer fw-semibold" htmlFor='allcartcheck'> {t("Product")}</label>
                                                    </div>
                                                </div>
                                                <div className='shoppingCartPage-cartTableBox-header-right'>
                                                    <div className='responsive'>{t("Price")}</div>
                                                    <div className='responsive'>{t("Stock")}</div>
                                                    <div className='responsive'>{t("Quantity")}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            {cartdata?.length ? cartdata.map((result, key) => {
                                                return (
                                                    <>
                                                        <div className={`shoppingCartPage-cartTableBox-content`} key={key}>
                                                            <div className='shoppingCartPage-cartTableBox-content-left'>
                                                                <div className='shoppingCartPage-cartTableBox-content-left-item'>
                                                                    <div className="com-ecomCheckBox">
                                                                        <input type="checkbox" className="itemscheckbox com-ecomCheckBox-inputcheck cursor-pointer"
                                                                            id={result?.id}
                                                                            checked={checkid?.some(item => item.id === result?.id)}

                                                                            onChange={(e) => checkcart(e, result)} />
                                                                        <label className="com-ecomCheckBox-labelBox cursor-pointer fw-semibold align-items-center"
                                                                            htmlFor={result?.id}
                                                                        >
                                                                            <span onClick={() => showDeleteConfirmation()} className='label-icon'>
                                                                                <img src={DeleteIconRed} alt='deleteicon' />
                                                                            </span>
                                                                            <img className='shoppingCartPage-cartTableBox-content-left-item-img' src={result?.main_image} alt='' />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div

                                                                    className='shoppingCartPage-cartTableBox-content-left-item-detail'>
                                                                    <p className='shoppingCartPage-cartTableBox-content-left-item-detail-name'>{result.name} </p>
                                                                    <Link
                                                                        to={result?.diamond ? "/mixandmatch" : `/product/${result?.motherProduct?.Slug}?v=${result?.id}`}
                                                                        state={result?.diamond ? { temp_cart_id: (shipdata.filter((item) => item.id === result?.id)?.[0]?.temp_cart_id), activeTab: 'Product' } : { id: result?.motherProduct?._id }}
                                                                        className='shoppingCartPage-cartTableBox-content-left-item-detail-sku'>
                                                                        {result?.SKU ? result?.SKU : "-"}
                                                                    </Link>
                                                                    <p className='shoppingCartPage-cartTableBox-content-left-item-detail-subname'>{t("Size : ")}{result?.Size ? result?.Size : ""}</p>
                                                                    <div className='shoppingCartPage-cartTableBox-content-left-item-detail-responsive'>
                                                                        <div className='shoppingCartPage-cartTableBox-content-left-item-detail-responsive-leftside'>
                                                                            <span>{t("In Stock")}</span>
                                                                            <div className={`cartQuantiyBox shoppingCartPage-cartTableBox-content-right-qty-btn ${result?.diamond ? "disabled" : ""}`}>
                                                                                <button disabled={result?.qty > 1 ? false : true} onClick={result?.diamond ? null : () => getQuantity(key, "minus", result?.qty)}>
                                                                                    <MinusIcon width={14} height={14} color={result?.diamond ? "#8A8A8A" : '#1D1D1F'} />
                                                                                </button>
                                                                                <div className='digit'>{result?.qty}</div>
                                                                                <button
                                                                                    onClick={result?.diamond ? null : () => getQuantity(key, "plus", result?.qty)}><PlusIcon width={14} height={14} color={result?.diamond ? "#8A8A8A" : '#1D1D1F'} />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        <div className='cartQuantiyBox shoppingCartPage-cartTableBox-content-right-price dark'>{result.price}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='cartQuantiyBox shoppingCartPage-cartTableBox-content-right'>
                                                                <div className='cartQuantiyBox shoppingCartPage-cartTableBox-content-right-price dark'>{result.price}</div>
                                                                <div className='cartQuantiyBox shoppingCartPage-cartTableBox-content-right-price'>{t("In Stock")}</div>
                                                                <div className="cartQuantiyBox shoppingCartPage-cartTableBox-content-right-qty">
                                                                    <div className={`cartQuantiyBox shoppingCartPage-cartTableBox-content-right-qty-btn ${result?.diamond ? "disabled" : ""}`}>
                                                                        <button disabled={result?.qty > 1 ? false : true} onClick={result?.diamond ? null : () => getQuantity(key, "minus", result?.qty)}>
                                                                            <MinusIcon width={14} height={14} color={result?.diamond ? "#8A8A8A" : '#1D1D1F'} />
                                                                        </button>
                                                                        <div className='digit'>{result?.qty}</div>
                                                                        <button
                                                                            onClick={result?.diamond ? null : () => getQuantity(key, "plus", result?.qty)}><PlusIcon width={14} height={14} color={result?.diamond ? "#8A8A8A" : '#1D1D1F'} />
                                                                        </button>
                                                                    </div>
                                                                    {/* <div className='quantity cursor-pointer' onClick={() => deleteItems(result?.id)}>{t("Remove")}</div> */}
                                                                </div>
                                                                {/* <div className='cartQuantiyBox shoppingCartPage-cartTableBox-content-right-total'>
                                                                {toFormatPrice(result.UnFormatPrice * result.qty, { addSymbol: true })}
                                                            </div> */}
                                                            </div>
                                                        </div>
                                                        {
                                                            result?.diamond ?
                                                                <div className="shoppingCartPage-cartTableBox-content mixandmatch" key={key + result?.id}>
                                                                    <div className='shoppingCartPage-cartTableBox-content-left'>
                                                                        <div className='shoppingCartPage-cartTableBox-content-left-item' key={key + result?.id + 1}>
                                                                            <div className="com-ecomCheckBox">
                                                                                <label className="com-ecomCheckBox-labelBox mixandmatch cursor-pointer fw-semibold align-items-center"
                                                                                    htmlFor={result?.id}
                                                                                >
                                                                                    <img className='shoppingCartPage-cartTableBox-content-left-item-img' src={result?.diamond?.main_image} alt='' />
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className='shoppingCartPage-cartTableBox-content-left-item-detail'>
                                                                            <p className='shoppingCartPage-cartTableBox-content-left-item-detail-name'>{result?.diamond?.name} </p>
                                                                            <Link
                                                                                to={result?.diamond ? "/mixandmatch" : `/product/${result?.motherProduct?.Slug}?v=${result?.id}`}
                                                                                state={result?.diamond ? { temp_cart_id: (shipdata.filter((item) => item.id === result?.id)?.[0]?.temp_cart_id), activeTab: 'Diamond' } : { id: result?.motherProduct?._id }}
                                                                                className='shoppingCartPage-cartTableBox-content-left-item-detail-sku'>
                                                                                {result?.diamond?.SKU ? result?.diamond?.SKU : "-"}
                                                                            </Link>
                                                                            <div className='shoppingCartPage-cartTableBox-content-left-item-detail-responsive'>
                                                                                <div className='shoppingCartPage-cartTableBox-content-left-item-detail-responsive-leftside'>
                                                                                    <span>{t("In Stock")}</span>
                                                                                    <div className={`cartQuantiyBox shoppingCartPage-cartTableBox-content-right-qty-btn ${result?.diamond ? "disabled" : ""}`}>
                                                                                        <button disabled={result?.qty > 1 ? false : true} onClick={result?.diamond ? null : () => getQuantity(key, "minus", result?.qty)}>
                                                                                            <MinusIcon width={14} height={14} color={result?.diamond ? "#8A8A8A" : '#1D1D1F'} />
                                                                                        </button>
                                                                                        <div className='digit'>{result?.qty}</div>
                                                                                        <button
                                                                                            onClick={result?.diamond ? null : () => getQuantity(key, "plus", result?.qty)}><PlusIcon width={14} height={14} color={result?.diamond ? "#8A8A8A" : '#1D1D1F'} />
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='cartQuantiyBox shoppingCartPage-cartTableBox-content-right-price dark'>{result?.diamond?.price}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='cartQuantiyBox shoppingCartPage-cartTableBox-content-right'>
                                                                        <div className='cartQuantiyBox shoppingCartPage-cartTableBox-content-right-price dark'>{result?.diamond?.price}</div>
                                                                        <div className='cartQuantiyBox shoppingCartPage-cartTableBox-content-right-price'>{t("In Stock")}</div>
                                                                        <div className="cartQuantiyBox shoppingCartPage-cartTableBox-content-right-qty">
                                                                            <div className={`cartQuantiyBox shoppingCartPage-cartTableBox-content-right-qty-btn ${result?.diamond ? "disabled" : ""}`}>
                                                                                <button disabled={result?.qty > 1 ? false : true} onClick={result?.diamond ? null : () => getQuantity(key, "minus", result?.qty)}>
                                                                                    <MinusIcon width={14} height={14} color={result?.diamond ? "#8A8A8A" : '#1D1D1F'} />
                                                                                </button>
                                                                                <div className='digit'>{result?.qty}</div>
                                                                                <button
                                                                                    onClick={result?.diamond ? null : () => getQuantity(key, "plus", result?.qty)}><PlusIcon width={14} height={14} color={result?.diamond ? "#8A8A8A" : '#1D1D1F'} />
                                                                                </button>
                                                                            </div>
                                                                            {/* <div className='quantity cursor-pointer' onClick={() => deleteItems(result?.id)}>{t("Remove")}</div> */}
                                                                        </div>
                                                                        {/* <div className='cartQuantiyBox shoppingCartPage-cartTableBox-content-right-total'>
                                                                {toFormatPrice(result.UnFormatPrice * result.qty, { addSymbol: true })}
                                                            </div> */}
                                                                    </div>
                                                                </div>
                                                                : ""}
                                                    </>
                                                )
                                            }) : ""}

                                        </div>
                                    </div>
                                    <div className='shoppingCartPage-cartTableBox-right'>
                                        <div className='shoppingCartPage-cartTableBox-right-head'>{t("ORDER SUMMARY")}</div>
                                        <div className='shoppingCartPage-cartTableBox-right-content'>
                                            <div className='shoppingCartPage-cartTableBox-right-content-one'>
                                                <span>{t("Subtotal")} {checkid?.length ? `(${checkid?.length} ${t("Items")})` : ""}</span>
                                                <span>
                                                    {checkid.length > 0 && checkid.length !== cartdata.length ?

                                                        toFormatPrice(
                                                            checkid.reduce((accumulator, product) => {
                                                                return accumulator + (product.price * (product.qty));
                                                            }, 0),
                                                            { addSymbol: true }
                                                        )

                                                        :
                                                        toFormatPrice(
                                                            cartdata.reduce((accumulator, product) => {
                                                                // Check if the product has the 'diamond' key
                                                                if (product.diamond) {
                                                                    return accumulator + (parseFloat(product?.UnFormatPrice) + parseFloat(product?.diamond?.UnFormatPrice))
                                                                } else {
                                                                    // Otherwise, multiply price by qty and add to accumulator
                                                                    return accumulator + (product.UnFormatPrice * (product.qty));
                                                                }
                                                            }, 0),
                                                            { addSymbol: true }
                                                        )
                                                    }
                                                </span>
                                            </div>
                                            <div className='shoppingCartPage-cartTableBox-right-content-two'>
                                                <span>{t("Total")}</span>
                                                <span>
                                                    {checkid.length > 0 && checkid.length !== cartdata.length ?

                                                        toFormatPrice(
                                                            checkid.reduce((accumulator, product) => {
                                                                return accumulator + (product.price * (product.qty));
                                                            }, 0),
                                                            { addSymbol: true }
                                                        )

                                                        :
                                                        toFormatPrice(
                                                            cartdata.reduce((accumulator, product) => {
                                                                // Check if the product has the 'diamond' key
                                                                if (product.diamond) {
                                                                    // If 'diamond' key exists, add the price directly
                                                                    return accumulator + (parseFloat(product?.UnFormatPrice) + parseFloat(product?.diamond?.UnFormatPrice))
                                                                } else {
                                                                    // Otherwise, multiply price by qty and add to accumulator
                                                                    return accumulator + (product.UnFormatPrice * (product.qty));
                                                                }
                                                            }, 0),
                                                            { addSymbol: true }
                                                        )
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <div className='shoppingCartPage-cartTableBox-right-subdata'>{t("Tax included and shipping calculated at checkout")}</div>
                                        <button className={`shoppingCartPage-cartTableBox-right-button ${checkid?.length > 0 ? '' : 'disabled'}`} onClick={checkid?.length > 0 ? () => negvigatecheckout("checkout", checkid) : null}>{t("Checkout")}</button>
                                    </div>
                                </div>
                                {/* <div className='shoppingCartPage-cartFooterbox'>
                                    <div className='shoppingCartPage-cartFooterbox-subTotalBox'>
                                        <p className=''>{t("SubTotal")}</p>
                                        <p> 
                                            {toFormatPrice(cartdata.reduce(
                                            (accumulator, product) => accumulator + product.UnFormatPrice * product?.qty,
                                            0), { addSymbol: true }
                                            )}
                                        </p>
                                    </div>
                                </div>
                                {checkid?.length ? <div className='com-confirmPurchasePage-main-footerBox mb-4'>
                                    <button className='com-confirmPurchasePage-main-footerBox-completeBtn' onClick={() => negvigatecheckout("checkout", checkid)}>{t("Checkout")}</button>
                                    <button className='com-confirmPurchasePage-main-footerBox-completeBtn' onClick={() => negvigatecheckout("appoinment", checkid)} >{t("Appointment")}</button>
                                </div> : ""} */}
                            </>
                        ) : (
                            <>
                                <div className='d-flex justify-content-center w-100 flex-column'>
                                    <p className='text-center  SizeGuied-subtitle' >{t("Your cart is currently empty.")}</p>
                                    <p className='text-center'>{t("Still don't have something you want? Try checking out the new products in case you have something you like to add to your cart.")}</p>
                                    <Link to="/" className='continue_shoppingbtn text-decoration-underline text-black mx-auto'><span>{t("Continue Shopping")}</span></Link>
                                </div>
                            </>
                        )}

                    </>)}

            </div>
            <DeleteConfirmation 
                isVisible={isPopupVisible}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                message="Remove Items"
            />
        </div>
    )
}

export default ShoppingCart