import React from 'react'
import { useTranslation } from 'react-i18next';

export default function ConfirmPopup({ isVisible, onConfirm, onCancel, message }) {
  const {t} = useTranslation();
  if (!isVisible) return null;
  return (
    <div className="confirmationpopup">
    <div className="confirmationpopup-inner">
        <button
                      type="button"
                      className="closebtn shadow-none border-0 bg-transparent"
                      onClick={onCancel}
                    >
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 13L7 7M7 7L1 1M7 7L13 1M7 7L1 13" stroke="#A4A4A4" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </button>
     <div className='confirmationpopup-inner-header'>
        <div className='confirmationpopup-inner-header-title'>{message}</div>
        <div className='confirmationpopup-inner-header-subtitle'>{("Let us know how you would like to customize this.")}</div>
     </div>
      <div className="confirmationpopup-inner-footer">
        <button className="confirmationpopup-inner-footer-buttons no" onClick={onCancel}>{t("No")}</button>
        <button className="confirmationpopup-inner-footer-buttons yes" onClick={onConfirm}>{t("Yes")}</button>
      </div>
    </div>
  </div>
  )
}
